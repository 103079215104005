import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AuthState, User } from 'interfaces/auth';

const AUTH_STATE = 'auth_state';
const saveAuthToSessionStorage = (authState: AuthState) => {
  sessionStorage.setItem(AUTH_STATE, JSON.stringify(authState));
};

const getAuthFromSessionStorage = (): AuthState | null => {
  const state = sessionStorage.getItem(AUTH_STATE);
  return state ? JSON.parse(state) : state;
};

const getInitialAuthState = () => {
  const initialState: AuthState = {
    isLoggedIn: false,
    isFetchingUserDetails: false,
  };

  return getAuthFromSessionStorage() ?? initialState;
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: getInitialAuthState(),
  reducers: {
    login: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
      state.isLoggedIn = true;
      state.isFetchingUserDetails = false;
      saveAuthToSessionStorage(state);
    },
    requestUserProfileUpdate: (state, action: PayloadAction<boolean>) => {
      state.profileUpdated = action.payload;
    },
    fetchingUserDetails: (state, action: PayloadAction<boolean>) => {
      state.isFetchingUserDetails = action.payload;
    },
    logout: (state) => {
      state.user = null;
      state.isLoggedIn = false;
      state.isFetchingUserDetails = false;
      saveAuthToSessionStorage(state);
    },
  },
});

export const { login, logout, fetchingUserDetails, requestUserProfileUpdate } = authSlice.actions;

export default authSlice.reducer;
